
      export default {
  "__schema": {
    "types": [
      {
        "kind": "INTERFACE",
        "name": "AdsAdvertisementEntity",
        "possibleTypes": [
          {
            "name": "AdsAdvertisementHtmlEmbed"
          },
          {
            "name": "AdsAdvertisementImage"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "BrochureLogEntity",
        "possibleTypes": [
          {
            "name": "AssigneeBrochureLog"
          },
          {
            "name": "CreateBrochureLog"
          },
          {
            "name": "PositionBrochureLog"
          },
          {
            "name": "StateBrochureLog"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "HotspotEntity",
        "possibleTypes": [
          {
            "name": "HotspotImageEntity"
          },
          {
            "name": "HotspotLinkEntity"
          },
          {
            "name": "HotspotProductEntity"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "WebsitePageEntity",
        "possibleTypes": [
          {
            "name": "WebsitePagePage"
          },
          {
            "name": "WebsitePagePost"
          }
        ]
      }
    ]
  }
}
    