import { render, staticRenderFns } from "./BaseText.vue?vue&type=template&id=064b5138&scoped=true"
import script from "./BaseText.vue?vue&type=script&lang=ts"
export * from "./BaseText.vue?vue&type=script&lang=ts"
import style0 from "./BaseText.vue?vue&type=style&index=0&id=064b5138&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "064b5138",
  null
  
)

export default component.exports